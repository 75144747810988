.spinner {
    animation:spin 1.44s linear infinite;
}

.form-title {
    text-align: left;
    padding-left: 9px;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.navbar {
  background: #eeeeff;
}

.sentence-list-item {
  margin-top: 7px;
}

.sentence-list-item span {
  margin-right: 10px;
}

.sentence-list-item input {
  margin-right: 35px;
  margin-top: 7px;
}

.sentence-list-item select {
  width: 150px;
  margin-right: 35px;
}

.flag {
  width: 24px;
  height: 18px;
  /* float: left; */
  /* margin-right: 20px; */
  position: absolute;
  right: 20px;
  top: 5px;
}

.timestamp {
  font-size: 11px;
  font-weight: bold;
  color: #555;
  line-height: 38px;
}

.translation {
  position: relative;
  margin-top: 4px;
}

.translation input {
  padding-right: 40px;
}

.sentence-container {
  border: 3px solid #fff;
}
.save-feedback {
  animation: save-feedback-keyframes 2s;
}

@keyframes save-feedback-keyframes {
  from {border: 3px solid rgba(10,150,40, 1.0);}
  to {border: 3px solid transparent;}
}